import { Cinders } from "../../backgrounds";

export const S1 = () => {
  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          left: 0,
          top: -100,
          zIndex: 2,
          width: "120%",
          position: "absolute",
          height: "calc(100% + 180px)",
          transform: "rotate(-1deg) translate(-10%, -100px)",
        }}
      >
        <Cinders />
      </div>
      <div
        className="container pt-5"
        style={{ position: "relative", zIndex: 300 }}
      >
        <div className="row">
          <div className="d-block d-lg-none col-12 align-items-center">
            <p className="about-p marker mb-5" style={{ color: "black" }}>
              Introducing Willy Whales, an average whale from the tough streets,
              whose life was a battle against poverty and neglect. Born to
              Jamaican immigrants in a neighborhood where dreams often faded,
              Willy’s early life was a struggle for survival, overshadowed by
              the allure of fast money and fleeting fame.
            </p>
          </div>
          <div className="col-12 col-lg-6">
            <img
              alt=""
              src="/images/whc-about1n.png"
              style={{
                width: "100%",
                userSelect: "none",
                objectFit: "contain",
                position: "relative",
                filter: "drop-shadow(2px 2px 12px rgba(0,0,0,.2))",
                zIndex: 5,
              }}
            />
          </div>
          <div className="d-none d-lg-flex col-12 col-lg-6 align-items-center">
            <p
              className="about-p marker mb-5"
              style={{ fontSize: 24, color: "black" }}
            >
              Introducing Willy Whales, an average whale from the tough streets,
              whose life was a battle against poverty and neglect. Born to
              Jamaican immigrants in a neighborhood where dreams often faded,
              Willy’s early life was a struggle for survival, overshadowed by
              the allure of fast money and fleeting fame.
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          transform: "perspective(1000px) rotateX(70deg)",
          position: "relative",
          zIndex: 299,
        }}
      >
        <div className="grass d-flex">
          <div style={{ width: "calc(100% / 3)", height: "100%" }}></div>
          <div
            style={{
              height: "100%",
              width: "33%",
              borderLeft: "1px solid #597149",
              borderRight: "1px solid #597149",
            }}
          ></div>
          <div style={{ width: "calc(100% / 3)", height: "100%" }}></div>
        </div>
      </div>
    </div>
  );
};
