import { Shandy } from "./Shandy";

export const ShandySm = () => {
  return (
    <div className="chandelier-holder">
      <div className="chain first-chains very-first-chain">
        <div className="chain first-chains">
          <div className="chain">
            <div className="chain chandelier chandelier-sm">
              <div
                style={{
                  height: 5,
                  width: "100%",
                  borderRadius: 30,
                  background: "#f8df9c",
                  boxShadow: "0px 0px 1px rgba(0,0,0,.35)",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const TripleShandy = () => {
  return (
    <div
      className="d-flex justify-content-around"
      style={{
        width: "100%",
        position: "absolute",
        left: 0,
        top: 0,
        zIndex: 100,
      }}
    >
      <ShandySm />
      <Shandy style={{ opacity: 0 }} />
      <ShandySm />
    </div>
  );
};
