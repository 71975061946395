import { Fence } from "../Decor/Fence";
import { Marbles } from "../../backgrounds/Marbles";
import { MarbleColumn } from "../Decor/MarbleColumn";

export const S7 = () => {
  return (
    <>
      <div
        className="d-block d-lg-none"
        style={{
          position: "relative",
          zIndex: 3,
          background:
            "linear-gradient(0deg, rgba(220,228,245,1) 0%, rgba(44,103,242,1) 100%)",
        }}
      >
        <img
          alt=""
          className="pt-5"
          src="/images/whc-about7n.png"
          style={{
            zIndex: 5,
            width: "100%",
            userSelect: "none",
            objectFit: "contain",
            filter: "drop-shadow(2px 2px 12px rgba(0,0,0,.2))",
            marginBottom: -30,
          }}
        />
        <div
          style={{
            width: "200%",
            height: 400,
            position: "relative",
            marginLeft: "-50%",
          }}
        >
          <Marbles>
            <div
              style={{
                marginTop: 20,
                marginLeft: "calc(25% + 20px)",
                background: "#e6e4d8",
                width: "calc(50% - 40px)",
              }}
              className="shadow p-2"
            >
              <p
                className="about-p marker"
                style={{ fontSize: 20, color: "#363636", textShadow: "none" }}
              >
                During these dark times, alone in his room, Willy encountered
                the world of cryptocurrency. It was here, amidst the despair,
                that he saw a glimmer of hope, a chance to rewrite his story. He
                dove into crypto, learning the ropes, making savvy investments
                that soon paid off. His portfolio grew “from negative to
                positive,” turning him into a local legend, Willy HODL.
              </p>
            </div>
          </Marbles>
        </div>
      </div>
      <div
        className="d-none d-lg-block"
        style={{
          position: "relative",
          zIndex: 3,
          background:
            "linear-gradient(0deg, rgba(220,228,245,1) 0%, rgba(44,103,242,1) 100%)",
        }}
      >
        <div
          style={{ width: "100vw" }}
          className="d-flex align-items-end justify-content-center"
        >
          <div style={{ width: "calc(25% - 70px" }}>
            <Fence />
          </div>
          <div style={{ height: 540, marginBottom: -280 }}>
            <MarbleColumn />
          </div>
          <div style={{ width: "50%", flexShrink: 0 }}>
            <img
              alt=""
              className="pt-5"
              src="/images/whc-about7n.png"
              style={{
                zIndex: 5,
                width: "100%",
                userSelect: "none",
                objectFit: "contain",
                filter: "drop-shadow(2px 2px 12px rgba(0,0,0,.2))",
                marginBottom: -30,
              }}
            />
          </div>
          <div style={{ height: 540, marginBottom: -280 }}>
            <MarbleColumn />
          </div>
          <div style={{ width: "calc(25% - 70px" }}>
            <Fence />
          </div>
        </div>
        <div style={{ width: "100%", height: 280, position: "relative" }}>
          <Marbles>
            <div
              style={{
                marginTop: 20,
                background: "#e6e4d8",
                width: "calc(50% - 40px)",
                marginLeft: "calc(25% + 20px)",
              }}
              className="shadow p-2"
            >
              <p
                className="about-p marker"
                style={{ fontSize: 20, color: "#363636", textShadow: "none" }}
              >
                During these dark times, alone in his room, Willy encountered
                the world of cryptocurrency. It was here, amidst the despair,
                that he saw a glimmer of hope, a chance to rewrite his story. He
                dove into crypto, learning the ropes, making savvy investments
                that soon paid off. His portfolio grew “from negative to
                positive,” turning him into a local legend, Willy HODL.
              </p>
            </div>
          </Marbles>
        </div>
      </div>
    </>
  );
};
