// import { Beam } from "../Decor/Beam";
// import { Column } from "../Decor/Column";
import { Shandy } from "../Decor/Shandy";

export const S5 = () => {
  return (
    <>
      <div
        style={{
          background: "#fcf9f1",
          position: "relative",
          overflow: "hidden",
          zIndex: 4,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <Shandy
                style={{
                  position: "relative",
                  zIndex: 3,
                  left: "50%",
                  transform: "translate(-50%, 0%)",
                }}
              />
              <div
                className="d-flex d-lg-none align-items-center"
                style={{ flexDirection: "column" }}
              >
                <div
                  className="shadow"
                  style={{
                    border: "16px solid white",
                    position: "relative",

                    marginTop: 80,
                    width: "90%",
                    zIndex: 2,
                  }}
                >
                  <img
                    alt=" "
                    style={{ width: "100%" }}
                    src="/images/whc-about5.png"
                  />
                  <div
                    style={{
                      position: "absolute",
                      left: 0,
                      top: 0,
                      width: "100%",
                      height: "100%",
                      boxShadow:
                        "inset 5px 5px 10px rgba(0,0,0,.5), inset -5px -5px 10px rgba(0,0,0,.25)",
                    }}
                  />
                </div>
                <p
                  className="about-p marker mt-4 mb-0"
                  style={{ color: "black", textShadow: "none" }}
                >
                  Willy’s lavish new lifestyle, complete with designer threads,
                  luxury cars, and opulent homes, became the talk of the town.
                  Yet, despite the riches, he remained true to his roots, never
                  forgetting where he came from. He knew all too well that “Mo
                  Money, Mo Problems” could be the reality of sudden wealth.
                </p>
                ;
              </div>
              <div className="d-none d-lg-flex justify-content-center">
                <div
                  className="shadow"
                  style={{
                    border: "16px solid white",
                    position: "relative",
                    marginBottom: 60,
                    marginTop: 80,
                    width: "35%",
                    zIndex: 2,
                  }}
                >
                  <img
                    alt=" "
                    style={{ width: "100%" }}
                    src="/images/whc-about5.png"
                  />
                  <div
                    style={{
                      position: "absolute",
                      left: 0,
                      top: 0,
                      width: "100%",
                      height: "100%",
                      boxShadow:
                        "inset 5px 5px 10px rgba(0,0,0,.5), inset -5px -5px 10px rgba(0,0,0,.25)",
                    }}
                  />
                </div>
              </div>

              <div
                className="align-items-center d-none d-lg-flex"
                style={{
                  flexDirection: "column",
                  position: "relative",
                  width: "100%",
                  zIndex: 6,
                }}
              >
                <Stair width="50%" text="Willy’s lavish new lifestyle,"></Stair>
                <Stair
                  width="55%"
                  text="complete with designer threads, luxury cars,"
                ></Stair>
                <Stair
                  width="60%"
                  text="and opulent homes, became the talk of the town."
                />
                <Stair
                  width="65%"
                  text="Yet, despite the riches, he remained true to his roots,"
                />
                <Stair
                  width="70%"
                  text="never forgetting where he came from."
                />
                <Stair width="75%" text="He knew all too well that" />
                <Stair width="80%" text="“Mo Money, Mo Problems” could"></Stair>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

//  <div style={{ position: "relative", width: "45%" }}>
//    <div
//      className="d-flex justify-content-between"
//      style={{
//        width: "100%",
//        position: "absolute",
//        transform: "translate(0%, -100%)",
//      }}
//    >
//      <div style={{ marginLeft: 14 }}>
//        <StartSection height={120} />
//      </div>
//      <div style={{ marginRight: 14 }}>
//        <StartSection height={120} />
//      </div>
//    </div>
//  </div>;

// <div style={{ position: "relative", width: "70%" }}>
//   <div
//     className="d-flex justify-content-between"
//     style={{
//       width: "100%",
//       position: "absolute",
//       transform: "translate(0%, -100%)",
//     }}
//   >
//     <div style={{ marginLeft: 14 }}>
//       <StartSection />
//     </div>
//     <div style={{ marginRight: 14 }}>
//       <StartSection />
//     </div>
//   </div>
// </div>;

// const StartSection = ({ height = 150 }) => {
//   return (
//     <>
//       <div
//         style={{
//           width: 28,
//           height: 28,
//           marginLeft: 17,
//           borderRadius: "50%",
//           background: "#521c01",
//           marginBottom: -10,
//         }}
//       ></div>
//       <div
//         style={{
//           width: 62,
//           height: 6,
//           background: "#521c01",
//         }}
//       ></div>
//       <div
//         style={{
//           width: 50,
//           height: height,
//           marginLeft: 6,
//           background: "#fff",
//           boxShadow: "inset 0px 3px 6px rgba(0,0,0,0.15)",
//         }}
//       ></div>
//       <div
//         style={{
//           width: 62,
//           height: 8,
//           background: "#521c01",
//         }}
//       ></div>
//     </>
//   );
// };

const Stair = ({ width, text }) => {
  return (
    <>
      <div
        className="d-flex justify-content-center"
        style={{ width: `calc(100% + 10px)` }}
      >
        <div
          style={{
            width: 5,
            height: 6,
            background: "#521c01",
          }}
        ></div>
        <div
          style={{
            width: width,
            height: 6,
            background: "#521c01",
          }}
        ></div>
        <div
          style={{
            width: 5,
            height: 6,
            background: "#521c01",
          }}
        ></div>
      </div>
      <div
        style={{
          height: 44,
          width: width,
          color: "black",
          background: "#fff",
          fontWeight: 600,
          fontSize: 20,
          boxShadow: `inset 0px 3px 6px rgba(0,0,0,0.15)`,
        }}
        className="d-flex justify-content-center align-items-center marker"
      >
        {text}
      </div>
    </>
  );
};

// <img
//   alt=""
//   src="/images/whc-about5n.png"
//   style={{
//     zIndex: 5,
//     width: "40%",
//     userSelect: "none",
//     objectFit: "contain",
//     position: "relative",
//     filter: "drop-shadow(2px 2px 12px rgba(0,0,0,.2))",
//   }}
//   className="mt-5"
// />
