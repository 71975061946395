import React, {
  useState,
  useContext,
  createContext,
  useEffect,
  useRef,
} from "react";
import { useScrollPosition } from "react-use-scroll-position";

const AppContext = createContext();

export default function AppContextWrapper({ children }) {
  const paddingRef = useRef(null);

  const [smallMenu, setSmallMenu] = useState(false);
  const { y: scrollTop } = useScrollPosition();
  const currentPage = window.location.href;
  const contRef = useRef(null);

  useEffect(() => {
    setSmallMenu(false);
  }, [currentPage]);

  const appLocalState = {
    contRef,
    scrollTop,
    smallMenu,
    paddingRef,
    setSmallMenu,
  };

  return (
    <AppContext.Provider value={appLocalState}>{children}</AppContext.Provider>
  );
}

export function useApp() {
  return useContext(AppContext);
}
