export const Fence = () => {
  return (
    <>
      <div className="d-flex justify-content-around">
        <div style={{ width: 5, height: 40, background: "black" }}></div>
        <div style={{ width: 5, height: 40, background: "black" }}></div>
        <div style={{ width: 5, height: 40, background: "black" }}></div>
        <div style={{ width: 5, height: 40, background: "black" }}></div>
        <div style={{ width: 5, height: 40, background: "black" }}></div>
        <div style={{ width: 5, height: 40, background: "black" }}></div>
        <div style={{ width: 5, height: 40, background: "black" }}></div>
        <div style={{ width: 5, height: 40, background: "black" }}></div>
        <div style={{ width: 5, height: 40, background: "black" }}></div>
      </div>
      <div style={{ width: "100%", height: 6, background: "black" }}></div>
      <div className="d-flex justify-content-around">
        <div style={{ width: 5, height: 180, background: "black" }}></div>
        <div style={{ width: 5, height: 180, background: "black" }}></div>
        <div style={{ width: 5, height: 180, background: "black" }}></div>
        <div style={{ width: 5, height: 180, background: "black" }}></div>
        <div style={{ width: 5, height: 180, background: "black" }}></div>
        <div style={{ width: 5, height: 180, background: "black" }}></div>
        <div style={{ width: 5, height: 180, background: "black" }}></div>
        <div style={{ width: 5, height: 180, background: "black" }}></div>
        <div style={{ width: 5, height: 180, background: "black" }}></div>
      </div>
    </>
  );
};
