import React from "react";
import { Bricks } from "./backgrounds";

//solana, WHC, 9VLtWhS7Zope97RAJ1KBk2n8FHcNDu5QxqXWv1A3WMko, price feed
const Banner = () => {
  return (
    <section className="banner">
      <div className="container">
        <div className="col-12 col-lg-7 main-col">
          <div className="banner-content-padding">
            <img
              alt=" "
              src="/images/title1.png"
              style={{ width: "100%", transform: "rotate(-2deg)" }}
              className="d-none d-lg-block"
            />
            <img
              alt=" "
              src="/images/title2.png"
              style={{ width: "100%", transform: "rotate(-2deg)" }}
              className="d-none d-lg-block"
            />
            <img
              alt=" "
              src="/images/titlesm.png"
              style={{ width: "100%" }}
              className="d-block d-lg-none"
            />
            <img
              alt=" "
              src="/images/title2sm.png"
              style={{ width: "100%" }}
              className="d-block d-lg-none"
            />
            <div className="d-flex justify-content-center">
              <a
                style={{ width: 350, maxWidth: "60%" }}
                href="https://solscan.io/token/9VLtWhS7Zope97RAJ1KBk2n8FHcNDu5QxqXWv1A3WMko"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  alt=" "
                  src="/images/btnBg3.png"
                  style={{ width: "100%" }}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <Bricks />
      <div style={{ position: "relative" }}>
        <img alt="" src="/images/whc1.png" className="boat-pos" />
      </div>
    </section>
  );
};

export default Banner;

//<div className="main-header">Big Willy Hodl's Whales Club</div>
