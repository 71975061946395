export const Beam = ({ bg, bs1 = "0.15", bs2 = "0.15" }) => {
  return (
    <>
      <div
        style={{ width: "100%", height: 6, background: bg ? bg : "white" }}
      ></div>
      <div
        style={{
          height: 40,
          width: "100%",
          background: bg ? bg : "white",
          boxShadow: `0 .5rem 1rem rgba(0,0,0,${bs1}), inset 2px 2px 2px rgba(0,0,0,${bs2})`,
        }}
      ></div>
    </>
  );
};
