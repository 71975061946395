import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const height = window.innerHeight;
  const navigate = useNavigate();

  return (
    <div>
      <div
        style={{
          height: height + 2,
          width: "100%",
          background: "#455c93",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <img
          alt=""
          src="/images/404.png"
          style={{ width: "80%", maxWidth: 440 }}
        />
        <div className="section-header" style={{ color: "white" }}>
          Somethings Missing
        </div>
        <div
          className="btn-1 hover-shadow hover-opacity"
          style={{ marginLeft: 0 }}
          onClick={() => navigate("/")}
        >
          Go Home
        </div>
      </div>
    </div>
  );
};

export default NotFound;
