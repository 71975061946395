import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import Whaleonomics from "../components/Whaleonomics";
import { Bricks } from "../components/backgrounds";
import Whitepaper from "../components/Whitepaper";
import GetStarted from "../components/BuyNow";
import Roadmap from "../components/Roadmap";
import TopBanner from "../components/Main";
import Features from "../components/About";
import Page from "../components/Page";
import Team from "../components/Team";

const HomePage = () => {
  const location = useLocation();

  // useEffect(() => {
  //   window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  // }, []);

  // useEffect(() => {
  //   if (location.hash) {
  //     let elem = document.getElementById(location.hash.slice(1));
  //     if (elem) {
  //       elem.scrollIntoView({ behavior: "smooth" });
  //     }
  //   } else {
  //     // window.scrollTo({top:0,left:0, behavior: "smooth"})
  //   }
  // }, [location]);

  return (
    <Page classes="app">
      <TopBanner />
      <Features />
      <div style={{ position: "relative" }}>
        <Whaleonomics />

        <GetStarted />
        <Team />
      </div>
    </Page>
  );
};

export default HomePage;
