const Highlight = ({ text, c1 = "white", c2 = "#2c67f2" }) => {
  return (
    <div style={{ position: "relative" }}>
      <div className="section-highlight-shadow" style={{ color: c2 }}>
        {text}
      </div>
      <div className="section-highlight" style={{ color: c1 }}>
        {text}
      </div>
    </div>
  );
};

export default Highlight;
