import { useMeasure } from "react-use";

export const Marbles = ({ top = true, children }) => {
  const [ref, { width, height }] = useMeasure();

  return (
    <div ref={ref} className="marble-wall">
      {width && (
        <InnerMarbles
          top={top}
          width={width}
          height={height}
          children={children}
        />
      )}
    </div>
  );
};

const InnerMarbles = ({ top, width, height, children }) => {
  const topBricks = [...new Array(6).fill({})];
  const topBrickWidth = width / 5;

  const bricks = [...new Array(7).fill({})];
  const brickWidth = width / 6;
  const brickHeight = brickWidth / 3;
  const rowCount = Math.ceil(height / brickHeight);
  const rows = [...new Array(rowCount).fill({})];

  return (
    <>
      {top && (
        <div className="marble-top shadow d-flex">
          {topBricks?.map((b, idx) => (
            <div
              key={idx}
              style={{
                width: topBrickWidth,
                height: "100%",
                flexShrink: 0,
              }}
            >
              <div className="marble-top-brick" />
            </div>
          ))}
        </div>
      )}
      <div style={{ position: "relative" }}>
        {rows?.map((r, id) => (
          <div
            key={id}
            className="d-flex"
            style={{
              transform:
                id % 2 === 0 ? "" : `translate(-${brickWidth / 2}px, 0px)`,
            }}
          >
            {bricks?.map((b, idx) => (
              <div
                key={idx}
                style={{
                  width: brickWidth,
                  height: brickHeight,
                  flexShrink: 0,
                }}
              >
                <div className="marble" />
              </div>
            ))}
          </div>
        ))}
        <div
          style={{
            top: 0,
            left: 0,
            zIndex: 300,
            width: "100%",
            position: "absolute",
          }}
        >
          {children}
        </div>
      </div>
    </>
  );
};
