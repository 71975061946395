export const S2 = () => {
  return (
    <>
      <div
        style={{
          zIndex: 3,
          position: "relative",
          background: "#7ba89c",
        }}
        className="d-block d-lg-none"
      >
        <div style={{ width: "100%", height: 25, background: "#4d5a5b" }}></div>
        <img
          alt=""
          src="/images/whc-about2.png"
          style={{
            zIndex: 5,
            width: "100%",
            userSelect: "none",
            objectFit: "contain",
            position: "relative",
            filter: "drop-shadow(2px 2px 12px rgba(0,0,0,.2))",
          }}
        />
        <div
          style={{
            width: "100%",
            background: "#202020",
            border: "16px solid #e7cfb4",
            boxShadow:
              "2px 2px 3px rgb(0,0,0,.25), inset 6px 6px 18px rgba(255,255,255,.1)",
          }}
          className="p-4"
        >
          <p className="about-p chalky" style={{ fontSize: 24 }}>
            In high school, Willy’s life hit a low note when his long-time
            girlfriend left him for someone wealthier, deepening his sense of
            failure. Laughed at and labeled a fool for dropping out, he felt his
            back against the wall, living a life with no direction, “considered
            a fool ‘cause I dropped out of high school.”
          </p>
        </div>
      </div>
      <div
        style={{
          zIndex: 3,
          position: "relative",
          background: "#7ba89c",
        }}
        className="d-none d-lg-block"
      >
        <div style={{ width: "100%", height: 25, background: "#4d5a5b" }}></div>
        <div
          style={{
            width: "100%",
            height: "45%",
            position: "absolute",
            left: 0,
            bottom: 0,
            background: "#849272",
            borderTop: "2px solid #4d5a5b",
          }}
        />
        <div
          style={{
            bottom: 0,
            left: "70%",
            width: "22%",
            height: "86%",
            position: "absolute",
            background: "#444744",
            borderTop: "16px solid #4d5a5b",
            borderLeft: "16px solid #4d5a5b",
            borderRight: "16px solid #4d5a5b",
            boxShadow: "-1px -1px 1px rgba(0,0,0,.5)",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              background: "#444744",
              boxShadow: "inset 3px 3px 6px rgba(0,0,0,.3)",
            }}
          ></div>
          <div
            style={{
              left: 20,
              top: "55%",
              width: 30,
              height: 30,
              borderRadius: "50%",
              position: "absolute",
              background: "#4d5a5b",
              boxShadow: "2px 2px 3px rgba(0,0,0,.15)",
            }}
          ></div>
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              top: 50,
              left: "50%",
              fontSize: 24,
              width: "70%",
              fontWeight: 600,
              position: "absolute",
              border: "12px solid #4d5a5b",
              transform: "translate(-50%, 0%)",
              boxShadow:
                "2px 2px 3px rgba(0,0,0,.15), inset 2px 2px 5px rgba(0,0,0,.25)",
              background: "#c2d5d1",
            }}
          >
            <img
              alt=""
              src="/images/brokenGlass.png"
              style={{ width: "100%" }}
            />
          </div>
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              top: 220,
              height: 50,
              left: "50%",
              fontSize: 20,
              width: "70%",
              fontWeight: 600,
              position: "absolute",
              background: "#4d5a5b",
              transform: "translate(-50%, 0%)",
              boxShadow: "2px 2px 3px rgba(0,0,0,.15)",
            }}
          >
            Whalegabra
          </div>
        </div>
        <div className="container">
          <div className="row pt-5">
            <div className="col-12 col-lg-6">
              <div style={{ transform: "rotate(-0.5deg)" }}>
                <div
                  style={{
                    width: "100%",
                    marginTop: "10%",
                    background: "#202020",
                    borderTop: "16px solid #e7cfb4",
                    borderLeft: "16px solid #e7cfb4",
                    borderRight: "16px solid #e7cfb4",
                    boxShadow:
                      "2px 2px 3px rgb(0,0,0,.25), inset 6px 6px 18px rgba(255,255,255,.1)",
                  }}
                  className="p-4"
                >
                  <p className="about-p chalky" style={{ fontSize: 28 }}>
                    In high school, Willy’s life hit a low note when his
                    long-time girlfriend left him for someone wealthier,
                    deepening his sense of failure. Laughed at and labeled a
                    fool for dropping out, he felt his back against the wall,
                    living a life with no direction, “considered a fool ‘cause I
                    dropped out of high school.”
                  </p>
                </div>
                <div
                  style={{
                    boxShadow: "2px 2px 3px rgb(0,0,0,.25)",
                    width: "calc(100% + 32px)",
                    background: "#e7cfb4",
                    marginLeft: -16,
                    height: 9,
                  }}
                ></div>
                <div
                  style={{
                    boxShadow:
                      "2px 2px 3px rgb(0,0,0,.25), inset 0px 2px 2px rgba(0,0,0,.12)",
                    background: "#e7cfb4",
                    width: "100%",
                    height: 16,
                  }}
                ></div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <img
                alt=""
                src="/images/whc-about2n.png"
                style={{
                  zIndex: 5,
                  width: "100%",
                  userSelect: "none",
                  objectFit: "contain",
                  position: "relative",
                  filter: "drop-shadow(2px 2px 12px rgba(0,0,0,.2))",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
