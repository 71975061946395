import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import SmallMenu from "./SmallMenu";
import { useApp } from '../context';

const Page = ({ children, classes }) => {
  const solidHeader = classes === 'podcast-post'
  document.body.style.overflowX = 'hidden';
  document.body.style.background = '#455c93';
  const { scrollTop, smallMenu, setSmallMenu, contRef } = useApp();

  return (
    <div style={{ overflowY: 'auto', overflowX: 'hidden' }} className={classes} ref={contRef}>
      <Header scrollTop={scrollTop} setSmallMenu={setSmallMenu} smallMenu={smallMenu} page='home' solidHeader={solidHeader} />
      {children}
      <Footer page={classes} />
      <SmallMenu open={smallMenu} setOpen={setSmallMenu} scrollTop={scrollTop} />
    </div>
  );
};

export default Page;
