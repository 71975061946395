import React from "react";
import { BsList } from "react-icons/bs";

const Navbar = ({ setSmallMenu, smallMenu }) => {
  return (
    <header className="header">
      <img
        alt=" "
        className="d-none d-lg-block"
        src="/images/headerBG.png"
        style={{ position: "absolute", left: 0, top: 0, width: "100vw" }}
      />
      <img
        alt=" "
        className="d-block d-lg-none"
        src="/images/headerBGSM.png"
        style={{ position: "absolute", left: 0, top: 0, width: "100vw" }}
      />
      <div className="container d-flex justify-content-between align-items-center">
        <a
          className="marker mt-1"
          style={{ fontSize: 38, color: "black", textDecoration: "none" }}
          href="/"
        >
          $WHC
        </a>
        <div className="d-flex align-items-center">
          <a className="header-item" href="#about">
            About
          </a>
          <a
            className="header-item"
            href="#whaleonomics"
            style={{ marginRight: 0 }}
          >
            Whaleonomics
          </a>

          <a
            className="header-item"
            href="https://solscan.io/token/9VLtWhS7Zope97RAJ1KBk2n8FHcNDu5QxqXWv1A3WMko"
            style={{ marginRight: 0 }}
            target="_blank"
            rel="noreferrer"
          >
            Contract
          </a>

          <a
            className="header-item"
            href="https://jup.ag/swap/SOL-WHC_9VLtWhS7Zope97RAJ1KBk2n8FHcNDu5QxqXWv1A3WMko"
            style={{ marginRight: 0 }}
            target="_blank"
            rel="noreferrer"
          >
            Buy Now
          </a>
        </div>
        <BsList
          style={{ color: "black" }}
          className="header-item-small"
          onClick={() => setSmallMenu(!smallMenu)}
        />
      </div>
    </header>
  );
};

export default Navbar;
