import { Cinders } from "../../backgrounds";

export const S3 = () => {
  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          left: 0,
          zIndex: 2,
          width: "120%",
          height: "100%",
          position: "absolute",
          transform: "translate(-10%, 0px)",
        }}
      >
        <Cinders />
      </div>
      <div className="container" style={{ position: "relative", zIndex: 300 }}>
        <div className="container pt-5">
          <div className="row mt-3 mt-lg-5">
            <div className="d-block d-lg-none col-12 align-items-center mb-4 mb-lg-0">
              <p className="about-p marker" style={{ color: "black" }}>
                During these dark times, alone in his room, Willy encountered
                the world of cryptocurrency. It was here, amidst the despair,
                that he saw a glimmer of hope, a chance to rewrite his story. He
                dove into crypto, learning the ropes, making savvy investments
                that soon paid off. His portfolio grew “from negative to
                positive,” turning him into a local legend, Willy HODL.
              </p>
            </div>
            <div className="col-12 col-lg-6">
              <img
                alt=""
                src="/images/whc-about3n.png"
                style={{
                  zIndex: 5,
                  width: "100%",
                  userSelect: "none",
                  objectFit: "contain",
                  filter: "drop-shadow(2px 2px 12px rgba(0,0,0,.2))",
                }}
              />
            </div>
            <div className="d-none d-lg-flex col-12 col-lg-6 align-items-center">
              <p
                className="about-p marker"
                style={{ fontSize: 24, color: "black" }}
              >
                During these dark times, alone in his room, Willy encountered
                the world of cryptocurrency. It was here, amidst the despair,
                that he saw a glimmer of hope, a chance to rewrite his story. He
                dove into crypto, learning the ropes, making savvy investments
                that soon paid off. His portfolio grew “from negative to
                positive,” turning him into a local legend, Willy HODL.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
