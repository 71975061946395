export const Sconch = () => {
  return (
    <div
      style={{
        left: "50%",
        position: "absolute",
        transform: "translate(-50%, -100%)",
      }}
    >
      <div
        style={{
          width: 40,
          height: 40,
          marginLeft: 10,
          background: "black",
          transform: "rotate(45deg) translate(65%, 65%)",
        }}
      ></div>
      <div
        style={{
          width: 40,
          height: 3,
          marginLeft: 10,
          background: "black",
          borderRadius: "10px 10px 0px 0px",
        }}
      ></div>
      <div
        style={{
          zIndex: 2,
          width: 60,
          height: 60,
          position: "relative",
          background: "#f8df9c",
          border: "5px solid black",
        }}
        className="d-flex justify-content-center"
      >
        <div
          style={{
            width: "calc(50% - 3px",
            height: "100%",
            boxShadow: "inset 5px 5px 10px rgba(0,0,0,.4)",
          }}
        ></div>
        <div style={{ width: 6, height: "100%", background: "black" }}></div>
        <div
          style={{
            width: "calc(50% - 3px",
            height: "100%",
            boxShadow: "inset 5px 5px 10px rgba(0,0,0,.4)",
          }}
        ></div>
      </div>
      <div
        style={{
          width: 12,
          height: 6,
          background: "black",
          marginLeft: 24,
        }}
      ></div>
      <div
        style={{
          width: 40,
          height: 3,
          marginLeft: 10,
          background: "black",
          borderRadius: "10px 10px 0px 0px",
        }}
      ></div>
    </div>
  );
};
