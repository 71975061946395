import Home from "./pages/Home";
import NotFound from "./pages/404";

import AppContextWrapper from "./context";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <AppContextWrapper>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route exact path="/" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </AppContextWrapper>
  );
}

export default App;
