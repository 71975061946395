import React from "react";
import Highlight from "../basic/Highlight";

import { S1, S2, S3, S4, S5, S6, S7 } from "./Sections";

const About = () => {
  return (
    <section id="about">
      <div className="d-flex align-items-center flex-column ">
        <Highlight text="About $WHC" c1="#000" c2="#f5cb45" />
        <div style={{ color: "black" }} className="section-header">
          Willy's Story
        </div>
      </div>
      <S1 />
      <S2 />
      <S3 />
      <S4 />
      <S5 />
      <S6 />
      <S7 />
    </section>
  );
};

export default About;
