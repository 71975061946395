import React, { useRef } from "react";
import { ThreeJSCave } from "./ThreeJSCave";
import { useIsInViewport } from "../hooks";
import { useWindowSizes } from "../hooks";
import { Link } from "react-router-dom";
import { useApp } from "../context";
import TreasureChest from "./Chest";

const items = [
  {
    title: "Jupiter",
    extras: [],
    link: "https://jup.ag/swap/SOL-WHC_9VLtWhS7Zope97RAJ1KBk2n8FHcNDu5QxqXWv1A3WMko",
    logo: "/images/jupiter-logo.png",
  },
  {
    title: "Phantom",
    extras: [],
    link: "https://phantom.app/download",
    logo: "/images/phantom-logo.png",
  },
];

const Cta = () => {
  return (
    <div style={{ position: "relative" }}>
      <div className="container d-flex align-items-center flex-column">
        <div id="buy" className="container section-container">
          <div
            className="block-title bt-1 text-center"
            style={{ position: "relative", zIndex: 70 }}
          >
            <div className="section-highlight">Get a Bag</div>
            <div
              className="section-header"
              style={{ textShadow: "1px 1px 2px rgba(0,0,0,.4)" }}
            >
              Buy $WHC
            </div>
            <div className="seperator" style={{ opacity: 0 }} />
          </div>
          <div className="row">
            <div className="d-none d-lg-block col-2" />
            {items?.map((item, key) => (
              <Item key={key} item={item} page={true} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

// <TreasureChest page={true} />

export default Cta;

const Item = ({ item }) => {
  const { title, logo } = item;

  return (
    <div className="col-12 col-md-6 col-lg-4 mb-4 mb-md-0">
      <Link className="service-one__single" to="/pricing">
        <div className="service-one__top">
          <div className="feature-content">
            <div
              className="section-header"
              style={{ color: "white", marginBottom: 0 }}
            >
              <img alt=" " src={logo} style={{ width: 150 }} />
            </div>
            <div className="feature-header mt-2">{title}</div>
          </div>
        </div>
      </Link>
    </div>
  );
};
