import React from "react";
import Highlight from "./basic/Highlight";

const Whaleonomics = () => {
  return (
    <section className="section-container" id="whaleonomics">
      <div className="container">
        <div
          className="d-flex align-items-center"
          style={{ flexDirection: "column" }}
        >
          <Highlight text="Coin Details" />
          <div className="section-header">Whaleonomics</div>
          <div className="row justify-content-center mt-3">
            <div className="col-12 col-lg-3 mb-4">
              <div className="section-bullet">
                <img
                  alt=""
                  src="/images/tokenSimple.png"
                  className="bullet-icon"
                />
                <div className="about-two__box-content">100 million supply</div>
              </div>
            </div>
            <div className="col-12 col-lg-3 mb-4">
              <div className="section-bullet">
                <img
                  alt=""
                  src="/images/tokenSimple.png"
                  className="bullet-icon"
                />
                <div className="about-two__box-content">
                  4 million burned (4%)
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-3 mb-4">
              <div className="section-bullet">
                <img
                  alt=""
                  src="/images/tokenSimple.png"
                  className="bullet-icon"
                />
                <div className="about-two__box-content">
                  96 million circulated
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-3 mb-4">
              <div className="section-bullet">
                <img
                  alt=""
                  src="/images/tokenSimple.png"
                  className="bullet-icon"
                />
                <div className="about-two__box-content">
                  4 million airdropped
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-3 mb-4">
              <div className="section-bullet">
                <img
                  alt=""
                  src="/images/tokenSimple.png"
                  className="bullet-icon"
                />
                <div className="about-two__box-content">No team tokens</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Whaleonomics;

// {
//   isInViewport1 && <Bubbles left="45%" />;
// }
// <img alt="" src="/images/" className="mrkt-img" />;
