import { Sconch } from "./Sconch";

export const MarbleColumn = () => {
  return (
    <div
      style={{
        zIndex: 200,
        flexShrink: 0,
        height: "100%",
        position: "relative",
      }}
    >
      <Sconch />
      <div
        style={{
          width: 80,
          height: 6,
          left: -5,
          position: "absolute",
          background: "#e6e4d8",
          boxShadow:
            "0 .5rem 1rem rgba(0,0,0,.15), inset 0px 2px 2px rgba(0,0,0,.12)",
        }}
      ></div>
      <div
        style={{
          boxShadow:
            "0 .5rem 1rem rgba(0,0,0,.15), inset 0px 8px 4px rgba(0,0,0,.15)",
          height: "100%",
          background: "#e6e4d8",
          width: 70,
        }}
      ></div>
    </div>
  );
};
