export const Column = ({ bg, bs1 = "0.15", bs2 = "0.12" }) => {
  return (
    <div
      style={{ flexShrink: 0, position: "relative", zIndex: 2, height: "100%" }}
    >
      <div
        style={{
          left: -5,
          width: 60,
          height: 6,
          position: "absolute",
          background: bg ? bg : "white",
          boxShadow: `0 .5rem 1rem rgba(0,0,0,${bs1}), inset 0px 2px 2px rgba(0,0,0,${bs2})`,
        }}
      ></div>
      <div
        style={{
          boxShadow: `0 .5rem 1rem rgba(0,0,0,${bs1}), inset 0px 8px 2px rgba(0,0,0,${bs2})`,
          background: bg ? bg : "white",
          height: "100%",
          width: 50,
        }}
      ></div>
    </div>
  );
};
