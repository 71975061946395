export const Bottle = ({
  className = "ms-3",
  color = "white",
  stemHeight = 40,
  stemWidth = 16,
  height = 80,
  zIndex = 1,
  style = {},
  width = 40,
  children,
  br = 30,
}) => {
  const stemLeft = (width - stemWidth) / 2;
  const stemTopLeft = (width - 18) / 2;

  return (
    <div
      className={className}
      style={{ position: "relative", zIndex: zIndex, ...style }}
    >
      <div
        style={{
          width: 18,
          height: 6,
          borderRadius: 1,
          background: color,
          marginLeft: stemTopLeft,
        }}
      ></div>
      <div
        style={{
          width: stemWidth,
          background: color,
          marginBottom: -10,
          height: stemHeight,
          marginLeft: stemLeft,
          boxShadow: "inset 0px 2px 2px rgba(0,0,0,.2)",
        }}
      ></div>
      <div
        style={{
          width: width,
          height: height,
          background: color,
          borderRadius: `${br}px ${br}px 4px 4px`,
          boxShadow:
            "6px 6px 12px rgba(0,0,0,.15), inset 6px 6px 12px rgba(0,0,0,.15)",
        }}
      >
        {children}
      </div>
    </div>
  );
};
