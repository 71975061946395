import React from "react";

const Team = () => {
  return (
    <section className="section-container" id="team">
      <div
        className="d-flex align-items-center"
        style={{ flexDirection: "column" }}
      >
        <div className="section-highlight">Community Driven</div>
        <div
          className="section-header"
          style={{ textShadow: "1px 1px 2px rgba(0,0,0,.4)" }}
        >
          $WHC Team
        </div>
      </div>
      <div
        className="d-flex align-items-center"
        style={{ flexDirection: "column" }}
      >
        <p
          style={{
            fontSize: 18,
            fontWeight: 600,
            maxWidth: 800,
            textAlign: "center",
          }}
          className="marker"
        >
          It’s important to note that this is a community-driven initiative,
          representing a takeover from the old developer who has since left.
          Members are actively involved in shaping its direction and success.
        </p>
        <img
          src="/images/teamPlane.png"
          alt="WHC team"
          style={{ width: "90%", maxWidth: 1000 }}
        />
      </div>
    </section>
  );
};

export default Team;
