import { useMeasure } from "react-use";

import { Beam } from "../Decor/Beam";
import { Column } from "../Decor/Column";
import { ShandySm, TripleShandy } from "../Decor/ShandySm";

export const S4 = () => {
  const [ref, { width, height }] = useMeasure();

  return (
    <>
      <div
        className="d-block d-lg-none"
        style={{ background: "#fcf9f1", position: "relative" }}
      >
        <div className="p-3">
          <p className="about-p marker" style={{ color: "black" }}>
            As his wealth surged, so did his reputation. From a “common thief to
            up close and personal with Robin Leach,” Willy’s transformation was
            nothing short of miraculous. He traded sardine dinners for champagne
            wishes, embodying the rags-to-riches tale he once thought
            unreachable.
          </p>
        </div>
        <img
          alt=""
          ref={ref}
          src="/images/whc-about4n.png"
          style={{
            zIndex: 5,
            width: "100%",
            userSelect: "none",
            objectFit: "contain",
            position: "relative",
            filter: "drop-shadow(2px 2px 12px rgba(0,0,0,.2))",
          }}
        />
      </div>
      <div
        className="d-none d-lg-block"
        style={{ background: "#fcf9f1", position: "relative" }}
      >
        <Beam />

        <div className="d-flex justify-content-between">
          <div
            style={{ width: "30%" }}
            className="d-flex justify-content-between"
          >
            <div style={{ height: height, marginBottom: -280 }}>
              <Column />
            </div>
            <div
              className="d-flex align-items-center"
              style={{ flexDirection: "column" }}
            >
              <ShandySm />
              <Painting />
            </div>
            <div style={{ height: height, marginBottom: -280 }}>
              <Column />
            </div>
          </div>
          <div
            className="d-flex justify-content-center"
            style={{ flexShrink: 0, width: "40%", position: "relative" }}
          >
            <TripleShandy />
            <img
              alt=""
              ref={ref}
              src="/images/whc-about4n.png"
              style={{
                zIndex: 5,
                width: "100%",
                userSelect: "none",
                objectFit: "contain",
                position: "relative",
                filter: "drop-shadow(2px 2px 12px rgba(0,0,0,.2))",
              }}
            />
          </div>
          <div
            style={{ width: "30%" }}
            className="d-flex justify-content-between"
          >
            <div style={{ height: height, marginBottom: -280 }}>
              <Column />
            </div>
            <div
              className="d-flex align-items-center"
              style={{ flexDirection: "column" }}
            >
              <ShandySm />
              <Painting2 />
            </div>
            <div style={{ height: height, marginBottom: -280 }}>
              <Column />
            </div>
          </div>
        </div>

        <div
          style={{
            zIndex: 5,
            width: "100%",
            background: "#fff",
            position: "relative",
            boxShadow: `0 .5rem 1rem rgba(0,0,0,0.05), 0px -2px 2px rgba(0,0,0,0.05)`,
            paddingBottom: 40,
          }}
        >
          <div
            style={{
              boxShadow: "0px 2px 2px rgba(0,0,0,0.08)",
              width: "100%",
              height: 6,
            }}
          />
          <div
            className="d-flex justify-content-between"
            style={{ width: "100%", paddingTop: 40 }}
          >
            <div
              style={{
                width: "15%",
                marginLeft: 50,
                boxShadow: `inset 0px 0px 4px rgba(0,0,0,0.16)`,
              }}
            ></div>
            <div style={{ width: 50, flexShrink: 0 }}></div>
            <div className="d-flex justify-content-center">
              <div style={{ width: width + 250 }}>
                <div
                  style={{
                    fontSize: 24,
                    width: "100%",
                    color: "black",
                    fontWeight: 600,
                    boxShadow: `inset 0px 0px 4px rgba(0,0,0,0.16)`,
                  }}
                  className="p-3 marker"
                >
                  As his wealth surged, so did his reputation. From a “common
                  thief to up close and personal with Robin Leach,” Willy’s
                  transformation was nothing short of miraculous. He traded
                  sardine dinners for champagne wishes, embodying the
                  rags-to-riches tale he once thought unreachable.
                </div>
              </div>
            </div>
            <div style={{ width: 50, flexShrink: 0 }}></div>
            <div
              style={{
                width: "15%",
                marginRight: 50,
                boxShadow: `inset 0px 0px 4px rgba(0,0,0,0.16)`,
              }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
};

const Painting = () => {
  return (
    <div
      className="shadow"
      style={{
        border: "16px solid white",
        position: "relative",
        marginTop: 160,
        width: "90%",
        zIndex: 2,
      }}
    >
      <img alt=" " style={{ width: "100%" }} src="/images/starry-night.jpeg" />
      <div
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
          boxShadow:
            "inset 5px 5px 10px rgba(0,0,0,.5), inset -5px -5px 10px rgba(0,0,0,.25)",
        }}
      />
    </div>
  );
};

const Painting2 = () => {
  return (
    <div
      className="shadow"
      style={{
        border: "16px solid white",
        position: "relative",
        marginTop: 120,
        width: "70%",
        zIndex: 2,
      }}
    >
      <img alt=" " style={{ width: "100%" }} src="/images/scream.jpeg" />
      <div
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
          boxShadow:
            "inset 5px 5px 10px rgba(0,0,0,.5), inset -5px -5px 10px rgba(0,0,0,.25)",
        }}
      />
    </div>
  );
};
