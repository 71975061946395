import { useMeasure } from "react-use";

export const Bricks = () => {
  const [ref, { width, height }] = useMeasure();
  const isSmall = width < 991;

  return (
    <div ref={ref} className="brick-wall">
      {width && <InnerBricks width={width} height={height} isSmall={isSmall} />}
    </div>
  );
};

const InnerBricks = ({ width, height, isSmall }) => {
  const count = isSmall ? 6 : 10;
  const bricks = [...new Array(count).fill({})];
  const brickWidth = width / (count - 1);
  const brickHeight = brickWidth / 3;
  const rowCount = Math.ceil(height / brickHeight);
  const rows = [...new Array(rowCount).fill({})];

  return rows?.map((r, id) => (
    <div
      key={id}
      className="d-flex"
      style={{
        transform: id % 2 === 0 ? "" : `translate(-${brickWidth / 2}px, 0px)`,
      }}
    >
      {bricks?.map((b, idx) => (
        <div
          key={idx}
          style={{
            width: brickWidth,
            height: brickHeight,
            padding: 2,
            flexShrink: 0,
          }}
        >
          <div className="brick" />
        </div>
      ))}
    </div>
  ));
};
