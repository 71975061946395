import { useMeasure } from "react-use";

import Bar from "../Bar";
import { Beam } from "../Decor/Beam";
import { Column } from "../Decor/Column";

export const S6 = () => {
  const [ref, { height }] = useMeasure();

  return (
    <>
      <div
        style={{
          background: "#202020",
          // background: "#240c00",
          position: "relative",
          overflow: "hidden",
          zIndex: 5,
        }}
        className="d-block d-lg-none"
      >
        <img
          alt=""
          src="/images/whc-about6.png"
          style={{
            width: "100%",
            userSelect: "none",
            objectFit: "contain",
          }}
        />
        <div
          className="d-flex justify-content-center align-items-center p-4"
          style={{ width: "100%", background: "#202020" }}
        >
          <p className="about-p neon-text-sm mb-0">
            With a heart as big as his bank account, Willy founded the Whales
            Club, dedicated to empowering others through knowledge and shared
            success. This club became a beacon for aspiring investors, a place
            where Willy’s mantra of “spread love, it’s the Brooklyn way”
            resonated with every member’s quest for financial freedom.
          </p>
        </div>
      </div>

      <div
        style={{
          background: "#202020",
          position: "relative",
          overflow: "hidden",
          zIndex: 5,
        }}
        className="d-none d-lg-block"
      >
        <Beam bg="#363636" bs1="0.3" bs2="0.3" />
        <div
          className="d-flex align-items-end justify-content-center"
          style={{ position: "relative", zIndex: 2 }}
        >
          <div style={{ height: height }}>
            <Column bg="#363636" bs1="0.3" bs2="0.3" />
          </div>
          <div ref={ref} style={{ width: "60%", position: "relative" }}>
            <div style={{ height: 500 }}></div>
            <div className="neon-wrap neon-text">Whales Club</div>
            <div>
              <img
                alt=""
                src="/images/whc-about6n.png"
                style={{
                  right: 0,
                  bottom: 0,
                  width: "100%",
                  userSelect: "none",
                  objectFit: "contain",
                  position: "absolute",
                  filter: "drop-shadow(2px 2px 6px rgba(0,0,0,.5))",
                }}
              />
              <Bar />
            </div>
          </div>
          <div style={{ height: height }}>
            <Column bg="#363636" bs1="0.3" bs2="0.3" />
          </div>
        </div>
      </div>
    </>
  );
};
