export const B1 = () => {
  return (
    <div style={{ paddingTop: 28 }}>
      <div
        style={{
          height: 26,
          fontSize: 12,
          width: "100%",
          color: "white",
          fontWeight: 600,
          background: "#de584b",
          fontFamily: "Sacramento",
          textShadow: "1px 1px 1px rgba(0,0,0,.3)",
        }}
        className="d-flex justify-content-center align-items-center"
      >
        Whale Juice
      </div>
    </div>
  );
};

export const B2 = () => {
  return (
    <div style={{ paddingTop: 24 }}>
      <div
        style={{
          height: 26,
          fontSize: 14,
          width: "100%",
          color: "white",
          fontWeight: 600,
          background: "#000",
          fontFamily: "Sacramento",
          textShadow: "1px 1px 1px rgba(0,0,0,.3)",
        }}
        className="d-flex justify-content-center align-items-center"
      >
        Fin
      </div>
    </div>
  );
};

export const B3 = () => {
  return (
    <div style={{ paddingTop: 20 }}>
      <div
        style={{
          background: "#D5AD6F",
          paddingTop: 8,
          height: 52,
        }}
      >
        <div
          style={{
            height: 26,
            fontSize: 10,
            width: "100%",
            color: "black",
            fontWeight: 600,
            fontFamily: "Sacramento",
            textShadow: "1px 1px 1px rgba(0,0,0,.3)",
            borderTop: "1px dashed black",
            borderBottom: "1px dashed black",
            paddingTop: 1,
          }}
          className="d-flex justify-content-center align-items-center"
        >
          WHC
        </div>
      </div>
    </div>
  );
};
