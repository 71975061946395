export const Shandy = ({ style = {} }) => {
  return (
    <div className="chandelier-holder" style={style}>
      <div className="chain first-chains very-first-chain">
        <div className="chain first-chains">
          <div className="chain">
            <div className="chain">
              <div className="chain chandelier">
                <div
                  style={{
                    height: 10,
                    width: "100%",
                    borderRadius: 100,
                    background: "#f8df9c",
                    boxShadow: "1px 1px 1px rgba(0,0,0,.2)",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
