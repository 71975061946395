export const WhiskyGlass = () => {
  return (
    <div
      style={{
        width: 32,
        height: 40,
        borderRadius: "1px 1px 5px 5px",
        background: "rgba(255,255,255,.12)",
        borderLeft: "1px solid rgba(255,255,255,.2)",
        borderRight: "1px solid rgba(255,255,255,.2)",
        borderBottom: "4px solid rgba(255,255,255,.2)",
      }}
      className="d-flex align-items-end ms-1"
    >
      <div
        style={{
          height: 3,
          width: "100%",
          background: "rgba(255,255,255,.1)",
        }}
      ></div>
    </div>
  );
};
