import "./styles.sass";

const Coin = ({ style = {}, className }) => {
  return(
    <div style={{ ...style, position: 'absolute' }} className={className}>
      <div className='coin'>

        <div className='front'>
          <div className='star'>
          </div>

          <div className='currency'>

          </div>

          <div className='shapes'>
            <div className='shape_l'>
            </div>
            <div className='shape_r'>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Coin;
