import { useMeasure } from "react-use";

export const Cinders = ({ top = true }) => {
  const [ref, { width, height }] = useMeasure();

  return (
    <div ref={ref} className="cinder-wall">
      {width && <InnerCinders top={top} width={width} height={height} />}
    </div>
  );
};

const InnerCinders = ({ top, width, height }) => {
  const topBricks = [...new Array(6).fill({})];
  const topBrickWidth = width / 5;

  const bricks = [...new Array(7).fill({})];
  const brickWidth = width / 6;
  const brickHeight = brickWidth / 3;
  const rowCount = Math.ceil(height / brickHeight);
  const rows = [...new Array(rowCount).fill({})];

  return (
    <>
      {top && (
        <div className="cinder-top shadow d-flex">
          {topBricks?.map((b, idx) => (
            <div
              key={idx}
              style={{
                width: topBrickWidth,
                height: "100%",
                flexShrink: 0,
              }}
            >
              <div className="cinder-top-brick" />
            </div>
          ))}
        </div>
      )}
      {rows?.map((r, id) => (
        <div
          key={id}
          className="d-flex"
          style={{
            transform:
              id % 2 === 0 ? "" : `translate(-${brickWidth / 2}px, 0px)`,
          }}
        >
          {bricks?.map((b, idx) => (
            <div
              key={idx}
              style={{
                width: brickWidth,
                height: brickHeight,
                flexShrink: 0,
              }}
            >
              <div className="cinder" />
            </div>
          ))}
        </div>
      ))}
    </>
  );
};
