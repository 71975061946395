import { useEffect } from "react";
import { useScrollLock } from "../hooks";

const SmallMenu = ({ open, setOpen, scrollTop }) => {
  const switchStyles = scrollTop > 140;
  const bg = switchStyles ? "#455c93" : "#000";
  const trans = open
    ? "height 250ms linear, background 250ms linear"
    : "height 250ms linear, background 250ms linear, opacity 100ms linear 200ms, z-index 100ms linear 200ms, padding 100ms linear 300ms";
  const { lockScroll, unlockScroll } = useScrollLock();

  useEffect(() => {
    if (open) {
      lockScroll();
    } else {
      unlockScroll();
    }
  }, [open, lockScroll, unlockScroll]);

  return (
    <div
      style={{
        position: "fixed",
        left: 0,
        top: 0,
        width: "100%",
        height: open ? "120%" : 0,
        background: bg,
        transition: trans,
        color: "white",
        overflow: "hidden",
        zIndex: open ? 999 : -1,
      }}
    >
      <div className="container pt-5">
        <div className="small-menu-item-wrap mt-5">
          <a className="small-menu-item" href="/#about">
            About $WHC
          </a>
        </div>
        <div className="small-menu-item-wrap">
          <a className="small-menu-item" href="/#whaleonomics">
            Whaleonomics
          </a>
        </div>
        <div className="small-menu-item-wrap">
          <a className="small-menu-item" href="/#roadmap">
            Roadmap
          </a>
        </div>
        <div className="small-menu-item-wrap">
          <a className="small-menu-item" href="/#whitepaper">
            Whitepaper
          </a>
        </div>
        <div className="small-menu-item-wrap">
          <a className="small-menu-item" href="/#buy">
            Buy Now
          </a>
        </div>
      </div>
    </div>
  );
};

export default SmallMenu;
